import React, { Component } from 'react';

import profilePic from '../img/profile-pic.png';

export class Blurb extends Component {
    render() {
        return (
            <section className="blurb">
                <div className="container">
                    <div>
                        <h1>I'm Raul Ardelean</h1>
                        <p>I'm a web developer.</p>
                        <p>Enjoying life's peaks and valleys, mostly when cycling around Wimbledon hill.</p>
                    </div>
                    <div>
                        <img className="blurb__avatar" src={profilePic} alt="Raul Ardelean" />
                    </div>
                </div>
            </section>
        );
    }
}

export default Blurb;