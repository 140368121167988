import React, { useState, useEffect } from 'react';
import DateHelper from '../helpers/date-helper';
import NumberHelper from '../helpers/number-helper';

export function AgeInSecondsCounter() {

    const [counter, setCounter] = useState(DateHelper.getAgeInSeconds());

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(prevCount => prevCount + 1);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (<b>{NumberHelper.addCommas(counter)}</b>);
}