import React, { Component } from 'react';

import { withAuth0 } from '@auth0/auth0-react';
import JSONPretty from 'react-json-pretty';

import Layout from '../components/Layout';
import Blurb from '../components/Blurb';
import UnnecessaryFacts from '../components/UnnecessaryFacts';

class Home extends Component {

    render() {
        const {
            user
        } = this.props.auth0;

        return (
            <Layout>
                <Blurb />
                <div className="container">
                    <section>
                        <UnnecessaryFacts />
                    </section>
                    <JSONPretty data={user} />
                </div>
            </Layout>
        );
    }
}

export default withAuth0(Home);