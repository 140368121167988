import React, { useState, useEffect } from 'react';

import copy from "copy-to-clipboard";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Command = ({ model }) => {

    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false)
            }, 1000);
        }
    });

    const copyToClipboard = () => {
        copy(model.line);
        setIsCopied(true);
    }

    return model.notes && (
        <div className="command-card">
            <p className="command-paragraph">{model.notes}</p>
            <div className="command-block">
                <span className="command-line">{model.line}</span>
                <Tippy content={isCopied ? "Copied" : "Copy to clipboard"} hideOnClick={false}>
                    <button className="command-copy-button command-pointer" onClick={copyToClipboard}>
                        <i className="command-copy-icon fa fa-copy" aria-hidden="true"></i>
                    </button>
                </Tippy>
            </div>
        </div>
    );
}

export default Command;