class DateHelper {
    getCelebrationDateForBillionSeconds() {
        var bd = this.getBirthDate();
        bd.setSeconds(bd.getSeconds() + 1000000000);
        const monthNames = this.getMonthNames();
        let month = monthNames[bd.getMonth()];
        let day = String(bd.getDate()).padStart(2, '0');
        let year = bd.getFullYear();
        let dt = month + '\n' + day + ', ' + year;
        return dt;
    }
    getMonthNames() {
        return ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
    }
    getBirthDate() {
        return new Date(1989, 1, 17);
    }
    getAgeInSeconds() {
        var dob = this.getBirthDate();
        var diffMs = Date.now() - dob.getTime();
        var ageDt = new Date(diffMs);
        return Math.round(ageDt.getTime() / 1000);
    }
    getCurrentYear(){
        return new Date().getFullYear();
    }
}

export default new DateHelper();