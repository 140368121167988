import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { ApplicationStep } from '../constants/application-steps.js';

export const Header = ({ model }) => {

    const { isMenuCollapsed, toggleMenu, location } = model;
    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        logout
    } = useAuth0();

    function getAuthenticationSection() {
        if (location.pathname === ApplicationStep.Authentication) {

            while (isLoading) {
                return <li className="nav-item"><span className="nav-link">Loading...</span></li>;
            }

            return isAuthenticated
                ? <li className="nav-item"><a className="nav-link" href="#" onClick={() => logout({ returnTo: window.location.href })}><span className="fas fa-sign-out-alt"></span> Logout</a></li>
                : <li className="nav-item"><a className="nav-link" href="#" onClick={() => loginWithRedirect()}><span className="fas fa-sign-in-alt"></span> Login</a></li>;
        }
        return null;
    }

    return (
        <nav className="py-3 navbar navbar-expand-md navbar-light">
            <div className="container navbar__container">
                <a className="navbar-brand" href="/">Raul Ardelean</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded={isMenuCollapsed} onClick={toggleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${!isMenuCollapsed && "show"}`}>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/projects">Projects</a>
                        </li>
                        {getAuthenticationSection()}
                    </ul>
                </div>
            </div>
        </nav>
    );
}