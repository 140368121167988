import React from 'react';
import history from "../history";

import { Header } from './Header.js';
import { Footer } from './Footer.js';

import MenuToggler from '../handlers/MenuToggler';

export const Layout = (props) => {
    return (
        <div>
            <MenuToggler render={(isMenuCollapsed, toggleMenu) => {
                return <Header model={{ isMenuCollapsed: isMenuCollapsed, toggleMenu: toggleMenu, location: history.location }} />
            }} />
            <div className="main">
                {props.children}
            </div>
            <Footer />
        </div>
    );
}

export default Layout;