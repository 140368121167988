import React, { useState, useEffect } from "react";

import FetchHelper from '../helpers/fetch-helper.js';
import UrlHelper from '../helpers/url-helper.js';

import { Layout } from '../components/Layout';
import Command from '../components/Command.js';

const Commands = () => {

    const [commands, setCommands] = useState([]);
    const [filteredCommands, setFilteredCommands] = useState([]);

    useEffect(() => {
        loadData();
    }, []);    

    const loadData = async () => {
        await FetchHelper.getAsync(UrlHelper.getCommandsApiUrl())
            .then((response) => {
                setCommands(response);
                setFilteredCommands(response);
            });
    }

    const filterData = (commands, searchValue) => {
        return commands.filter((command) => command.notes.search(searchValue) !== -1 || command.line.search(searchValue) !== -1);
    }

    const handleSearch = (event) => {
        const { value } = event.target;

        if (value !== "") {
            let result = filterData(commands, value);
            setFilteredCommands(result);
        } else {
            setFilteredCommands(commands);
        }
    }

    return (filteredCommands &&
        <Layout>
            <div className="main__inner">
                <div className="container">

                    <h1>Commander</h1>

                    <div className="commander-search-bar">
                        <i className="commander-search-bar-icon fa fa-search"></i>
                        <input className="commander-search-bar__input" type="search" placeholder="Search commands..." onChange={handleSearch} />
                    </div>

                    {filteredCommands && filteredCommands.map((command) => {
                        return <Command key={command.id} model={command} />
                    })}
                </div>
            </div>
        </Layout>
    );
}

export default Commands;