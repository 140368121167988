import React from 'react';

class MenuToggler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuCollapsed: true
        };
    }

    toggleMenu = () => {
        this.setState({ isMenuCollapsed: !this.state.isMenuCollapsed });
    }

    render() {
        return (this.props.render(this.state.isMenuCollapsed, this.toggleMenu));
    }
}

export default MenuToggler;