class CacheHelper {

    set(key, object) {
        if (typeof (Storage) !== "undefined") {
            window.sessionStorage.setItem(key, JSON.stringify(object));
        }
    }

    get(key) {
        if (typeof (Storage) !== "undefined") {
            return JSON.parse(window.sessionStorage.getItem(key));
        }

        return undefined;
    }

    remove(key) {
        if (typeof (Storage) !== "undefined") {
            window.sessionStorage.removeItem(key);
        }
    }

    clear(prefix) {
        if (typeof (Storage) !== "undefined") {
            if (prefix) {
                this._clearByPrefix(prefix);
            } else {
                window.sessionStorage.clear();
            }
        }
    }

    _clearByPrefix(prefix) {
        var keys = []; // Array to hold the keys
        // Iterate over sessionStorage and insert the keys that meet the condition into arr
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).substring(0, prefix.length) === prefix) {
                keys.push(sessionStorage.key(i));
            }
        }

        // Iterate over arr and remove the items by key
        for (let i = 0; i < keys.length; i++) {
            this.remove(keys[i]);
        }
    }
}

export default new CacheHelper();