import React, { useState } from "react";

import { Layout } from '../components/Layout';

import { StaticData } from "../staticData/static-data";

const Projects = ({ match }) => {

    const [viewModel, setViewModel] = useState(StaticData.getMockedProjects());
    const { projects } = viewModel;

    return (
        <Layout>
            <div className="main__inner">
                <div className="container">
                    <div className="card-deck">
                        {projects.map((project) => {
                            return (<div className="card" key={project.projectId}>
                                <div className="card-body">
                                    <h5 className="card-title">{project.name}</h5>
                                    <p className="card-text">{project.description}</p>
                                </div>
                                <div className="card-body">
                                    <a href={`${match.url}/${project.projectId}`} className="card-link">View details</a>
                                </div>
                            </div>);
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Projects;