import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        return (
            <footer className="site-footer">
                <div className="site-footer__inner">
                    
                </div>
            </footer>
        );
    }
}
