import React, { Fragment } from 'react';

import { AgeInSecondsCounter } from './AgeInSecondsCounter';

const UnnecessaryFacts = () => {
    return (
        <Fragment>
            <h2>Unnecessary facts:</h2>
            <ul>
                <li><p>I'm <AgeInSecondsCounter /> seconds old <span aria-label="anxiously screaming in fear" role="img">😰&nbsp;</span></p></li>
                <li><p>This website is hosted on a S3 bucket</p></li>
            </ul>
        </Fragment>
    );
}

export default UnnecessaryFacts;