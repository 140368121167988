import CacheHelper from './cache-helper.js';

class FetchHelper {

    // async wrapper
    getAsync(url, useCache = true) {
        return new Promise((resolve, reject) => {
            this.get(
                url,
                (result) => resolve(result),
                (e) => reject(e),
                useCache);
        });
    }

    get(url, success, error, useCache = false) {
        if (useCache) {
            let obj = CacheHelper.get(url);
            if (obj === null) {
                this._get(url,
                    (result) => {
                        CacheHelper.set(url, result);
                        success(result);
                    },
                    (e) => {
                        error(e);
                    }
                );
            } else {
                success(obj);
            }

        } else {
            this._get(url, success, error);
        }
    }

    _get(url, success, error) {

        let request = new Request(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(success, error)
            .catch(error);
    }

    // async wrapper
    getTextAsync(url) {
        return new Promise((resolve, reject) => {
            this.getText(
                url,
                (result) => resolve(result),
                (e) => reject(e));
        });
    }

    getText(url, success, error) {
        fetch(url, { credentials: 'include' })
            .then(res => res.text())
            .then(success, error)
            .catch(error);
    }

    postAsync(url, data) {
        return new Promise((resolve, reject) => {
            this.post(
                url,
                data,
                (result) => resolve(result),
                (e) => reject(e));
        });
    }

    post(url, data, success, error) {
        fetch(url,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(success, error)
            .catch(error);
    }

    postWithResponse(url, data, success, error) {
        fetch(url,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(function (response) {
                return response.text();
            })
            .then(success, error);
    }

    postWithJsonResponse(url, data, success, error) {
        fetch(url,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(function (response) {
                return response.json();
            })
            .then(success, error);
    }
}

export default new FetchHelper();