import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom';
import history from "./history";

import Home from './pages/Home';
//import Authentication from './pages/Authentication';
import Projects from './pages/Projects';
import Commands from './pages/Commands';

class App extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path='/projects' exact component={Projects} />
                    <Route path='/projects/commander' exact component={Commands} />
                    {/* <Route path='/authentication' component={Authentication} /> */}
                </Switch>
            </Router>
        );
    }
}

export default App;