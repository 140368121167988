import mockData from './MOCK_DATA.json';

export class StaticData {
    static getMockedRecipes() {
        return {
            foodRecipes: [
                {
                    recipeId: "1",
                    type: "Fel principal",
                    name: "Mancare de spanac",
                    description: "Mancare de spanac",
                    ingredients: [
                        { description: "1/2 kg spanac" },
                        { description: "1 lingura de faina" },
                        { description: "3,4 linguri ulei sau unt(30 gr)" },
                        { description: "3,4 catei de usturoi" },
                        { description: "250 ml lapte" },
                        { description: "Sare" }
                    ],
                    cookingSteps: [
                        { description: "Spanacul se spala bine in apa inainte de a se rupe frunzele" },
                        { description: "Cand este spalat bine se rup frunzele fara codite si se opareste aproximativ 20 minute" },
                        { description: "Intr-o cratita se pune faina si uleiul, se infierbanta 2 minute fara sa se rumeneasca, apoi se pune usturoiul tocat si imediat pureeul de spanac" },
                        { description: "Se subtiaza cu lapte" },
                        { description: "Se poriveste de sare" },
                        { description: "Se fierbe pana se ingroase, aprox. 5,10 minute" }
                    ],
                    cookingTime: "1 ora",
                    skillLevel: "Usor",
                    servings: "4",
                    notes: ""
                }, {
                    recipeId: "2",
                    type: "Fel principal",
                    name: "Ciorba de pui olteneasca",
                    description: "Ciorba de pui olteneasca",
                    ingredients: [
                        { description: "1 kg carne de pui cu os" },
                        { description: "3 rosii decojite si tocate" },
                        { description: "2 cartofi" },
                        { description: "40 g fidea" },
                        { description: "1 legatura patrunjel" },
                        { description: "1 legatura leustean" },
                        { description: "1 dovlecel" },
                        { description: "2 cepe" },
                        { description: "1 radacina pastarnac" },
                        { description: "2 morcovi" },
                        { description: "1/4 telina" },
                        { description: "1 ardei gras" },
                        { description: "1 radacina patrunjel" },
                        { description: "sare" }
                    ],
                    cookingSteps: [
                        { description: "Pentru inceput vom pune carnea de pui la fiert" },
                        { description: "Adaugam sare si fierbem carnea pana cand spuma se va forma la suprafata" },
                        { description: "Spuma se inlatura cu o lingura sau o paleta" },
                        { description: "Dupa ce carnea carnea este fiarta iar spuma nu se mai formeaza la suprafata adaugam legumele" },
                        { description: "Adaugam ceapa, apoi punem radacinoasele(patrunjelul, pastarnacul, telina, morcovii, ardeiul gras si cartofii scursi de apa, toate tocate dupa plac)" },
                        { description: "Amestecam si lasam legumele sa fiarba pentru aproximativ 20 de minute pana cand legumele sunt fierte" },
                        { description: "Cand legumele au fiert adaugam dovleceii si rosiile decojite si tocate" },
                        { description: "Se amesteca dupa care se adauga si fideaua si putina sare dupa gust" },
                        { description: "Se lasa ciorba sa fiarba pentru aproximativ 5 minute pana ce s-au fiert si dovleceii si rosiile" },
                        { description: "Cand ciorba este gata, se opreste focul si se adauga verdeata(patrunjel, leustean)" }
                    ],
                    cookingTime: "1 ora 30 minute",
                    skillLevel: "Mediu",
                    servings: "10",
                    notes: ""
                }
            ]
        };
    }

    static getMockedProjects() {
        return {
            projects: [
                {
                    projectId: "commander",
                    name: "Commander",
                    description: "Commands are a set of inputs that help communicate with a machine or a program, they vary by technology and are frequently used in computer programming and because there's too many of them I've decided to work on this project to help me centralize and categorize the ones that are most relevant to me! Work in progress..."
                }
            ]
        }
    }

    static getMockedCommands() {
        return mockData;
    }
}